'use client'

import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Cell, Text, Spacer } from '@vinted/web-ui'

import { DeprecatedInputText, renderValidation } from 'components/Input'
import useAbTest from 'hooks/useAbTest'
import useTranslate from 'hooks/useTranslate'
import useFormValidationMessage from 'hooks/useFormValidationMessage'
import useFetch from 'hooks/useFetch'

import { getTransactionUserSide } from 'state/conversation/selectors'

import { getPriceEstimateWithFees } from 'data/api'
import { transformPriceEstimateWithFees } from 'data/transformers/price-estimate-with-fees'

import { InputMode } from 'types/components'
import { CurrencyAmountModel } from 'types/models'

import { formatCurrencyAmount } from 'libs/utils/formatString'

import { PRICE_PATTERN } from 'constants/input'
import { AbTestVariant } from 'constants/abtest'
import { TransactionSide } from 'constants/transaction'
import { EscrowFees } from 'constants/escrow-fees'

import useDebounce from 'hooks/useDebounce'

import { OfferFormModel, OfferFormField } from '../types'

type Props = {
  showTitle?: boolean
  maxDiscount?: string
  minItemPrice: CurrencyAmountModel | undefined
  maxItemPrice: CurrencyAmountModel | undefined
  currentItemPrice: CurrencyAmountModel
  itemId: number
  orderItemIds?: Array<number>
}

const OfferPriceField = ({
  maxDiscount,
  minItemPrice,
  maxItemPrice,
  currentItemPrice,
  showTitle = true,
  itemId,
  orderItemIds,
}: Props) => {
  const {
    register,
    setValue,
    setFocus,
    formState: { errors },
  } = useFormContext<OfferFormModel>()

  const { fetch: fetchPriceEstimateWithFees, transformedData: priceEstimate } = useFetch(
    getPriceEstimateWithFees,
    transformPriceEstimateWithFees,
  )

  const { locale } = useIntl()
  const translationPrefix = 'conversation.hero_message.make_offer'
  const translate = useTranslate(translationPrefix)
  const validationMessage = useFormValidationMessage(errors, `${translationPrefix}.offer_modal`)
  const maxOfferDiscountRequestAbTestVariant = useAbTest({
    abTestName: 'maximum_offer_request_discount',
  })?.variant

  const getItemIds = useCallback(
    () => (orderItemIds ? orderItemIds.map(String) : [String(itemId)]),
    [orderItemIds, itemId],
  )
  const DEBOUNCE_DELAY = 600
  const userSide = useSelector(getTransactionUserSide)
  const getTotalPriceEstimate = useDebounce(value => {
    fetchPriceEstimateWithFees({
      item_ids: getItemIds(),
      offer_price: {
        amount: value as string,
        currency_code: currentItemPrice.currencyCode,
      },
      fees: [EscrowFees.BuyerProtection],
    })
  }, DEBOUNCE_DELAY)

  useEffect(() => {
    setValue(OfferFormField.Offer, '')

    fetchPriceEstimateWithFees({
      item_ids: getItemIds(),
      offer_price: {
        amount: currentItemPrice.amount,
        currency_code: currentItemPrice.currencyCode,
      },
      fees: [EscrowFees.BuyerProtection],
    })
  }, [
    setValue,
    currentItemPrice.amount,
    currentItemPrice.currencyCode,
    fetchPriceEstimateWithFees,
    getItemIds,
  ])

  useEffect(() => {
    setFocus(OfferFormField.Offer)
  }, [setFocus])

  const getMinItemPriceMessage = (minPrice: CurrencyAmountModel) => {
    const isBuyer = userSide === TransactionSide.Buyer
    const isMaxOfferDiscountRequestAbTestEnabled =
      maxOfferDiscountRequestAbTestVariant === AbTestVariant.A ||
      maxOfferDiscountRequestAbTestVariant === AbTestVariant.B

    const formattedMinItemPrice = formatCurrencyAmount(minPrice, locale)
    const formattedMaxDiscount = isBuyer
      ? Number(maxDiscount).toLocaleString(locale, { style: 'percent' })
      : undefined

    if (!isMaxOfferDiscountRequestAbTestEnabled || !formattedMaxDiscount) {
      return translate('offer_modal.offer.errors.value_too_low', {
        value: formattedMinItemPrice,
      })
    }

    return translate('offer_modal.offer.errors.value_too_low_with_percentage', {
      amount: formattedMinItemPrice,
      percentage: formattedMaxDiscount,
    })
  }

  const min = minItemPrice
    ? {
        min: {
          value: minItemPrice.amount,
          message: getMinItemPriceMessage(minItemPrice),
        },
      }
    : {}

  const max = maxItemPrice
    ? {
        max: {
          value: maxItemPrice.amount,
          message: translate('offer_modal.offer.errors.value_too_high', {
            value: formatCurrencyAmount(maxItemPrice, locale),
          }),
        },
      }
    : {}

  const getInputValidationDefaultText = () => {
    const totalCombinedPrice = priceEstimate?.totalPrice

    if (!totalCombinedPrice) return null

    return (
      <Text
        testId="total-combined-price"
        type={Text.Type.Caption}
        text={translate('offer_modal.total_combined_price', {
          price: formatCurrencyAmount(totalCombinedPrice, locale),
        })}
      />
    )
  }

  const getInputValidation = () => {
    const defaultText = getInputValidationDefaultText()

    return renderValidation(validationMessage(OfferFormField.Offer)) || defaultText
  }

  const handleOfferPriceChange = ({ target: { value } }) => {
    setValue(OfferFormField.Offer, value)
    getTotalPriceEstimate(value || currentItemPrice.amount)
  }

  return (
    <Cell>
      {showTitle && (
        <>
          <Text
            type={Text.Type.Subtitle}
            text={translate('offer_modal.subtitle')}
            testId="offer-price-field-title"
          />
          <Spacer size={Spacer.Size.Small} />
        </>
      )}
      <DeprecatedInputText
        {...register(OfferFormField.Offer, {
          ...min,
          ...max,
          required: {
            value: true,
            message: translate('offer_modal.offer.errors.required'),
          },
          pattern: PRICE_PATTERN,
        })}
        onChange={handleOfferPriceChange}
        placeholder={formatCurrencyAmount(currentItemPrice, locale)}
        inputMode={InputMode.Decimal}
        step="0.01"
        type="number"
        styling="tight"
        validation={getInputValidation()}
        uncontrolled
        testId="offer-price-field"
      />
    </Cell>
  )
}

export default OfferPriceField
